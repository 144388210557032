@import '../../App.scss';

.bc-nil {
	background-color: #000000;

	h1 {
		font-family: refrigerator-deluxe;
		font-style: normal;
		font-weight: 700;
		font-size: 72px;
		line-height: 100%;

		text-transform: uppercase;

		color: #ffffff;
	}

	h2 {
		font-family: refrigerator-deluxe;
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
		line-height: 100%;

		text-transform: uppercase;

		color: #ffffff;
	}

	p {
		margin: 0px;

		font-family: interstate;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.15px;

		color: #ffffff;
	}

	.bc-nil-header {
		position: relative;

		.bc-nil-image {
			width: 100%;
			height: 80vh;

			object-fit: cover;
		}

		.bc-nil-header-description {
			position: absolute;

			right: 0;
			top: 0;

			width: 50%;
			height: 100%;

			padding-left: 120px;
			padding-right: 120px;

			background: linear-gradient(
				270deg,
				#000000 29.11%,
				rgba(0, 0, 0, 0) 199.58%
			);
			// big
			@media (min-width: $xl) {
			}

			// medium
			@media (max-width: $xl) {
				padding: 32px;
			}

			// small
			@media (max-width: $md) {
				position: relative;

				width: 100%;
				height: auto;

				padding: 32px;
				background-color: #000000;
			}

			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.bc-nil-banner {
		background-color: #dbd4c2;
		height: 180px;
		width: 100%;

		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.bc-nil-product-grid {
		display: grid;

		grid-auto-flow: column;
		grid-auto-columns: 1fr;

		height: 50vw;

		// small
		@media (max-width: $lg) {
			grid-auto-flow: row;
			grid-auto-columns: 1fr;

			height: auto;
		}

		.bc-nil-product-image {
			width: 100%;

			@media (min-width: $lg) {
				height: 50vw;
			}

			@media (max-width: $lg) {
				height: 100vw;
			}

			object-fit: cover;
		}

		.bc-nil-product-description {
			padding: 8% 12%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 50vw;

			// // medium
			@media (max-width: $lg) {
				padding: 32px;
				height: 75vw;

				order: 1;
			}

			@media (max-width: $md) {
				padding: 32px;
				height: 125vw;

				order: 1;
			}

			@media (max-width: $md) {
				padding: 32px;
				height: 225vw;

				order: 1;
			}

			.thing {
				flex: 1;
				overflow: auto;
				margin-top: 24px;
			}

			.size-dropdown {
				margin-right: 32px;
				@media (max-width: $md) {
					margin-right: 0px;
				}
			}

			.bc-nil-purchase {
				padding-left: 32px;
				padding-right: 32px;
				padding-top: 18px;
				padding-bottom: 18px;

				font-family: interstate;
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 100%;

				text-align: center;

				color: #ffffff;

				@media (max-width: $md) {
					margin-top: 24px;

					width: 100%;
				}
			}
		}
	}

	.bc-nil-charity {
		text-align: center;

		display: flex;
		flex-direction: column;
		align-items: center;

		align-self: center;

		// big
		@media (min-width: $xl) {
			// max-width: 50%;
			padding: 120px;
		}

		// medium
		@media (max-width: $xl) {
			padding: 60px;
		}

		// small
		@media (max-width: $md) {
			padding: 32px;
		}

		h2 {
			color: #8a7f52;

			@media (max-width: $md) {
				margin-bottom: 16px;
				font-size: 32px;
			}
		}

		p {
			color: #ffffff;

			font-family: interstate;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.15px;

			@media (min-width: $xl) {
				padding-left: 240px;
				padding-right: 240px;
			}
		}
	}
}
