@import '../../../App.scss';

.athletes {
	width: 100%;

	background-color: #000000;

	.bc-vertical-items {
		display: grid;

		grid-auto-flow: column;
		grid-auto-columns: 1fr;

		@media (min-width: $xs) {
			grid-auto-flow: row;
			grid-template-columns: 1fr;
		}

		@media (min-width: $sm) {
			grid-auto-flow: row;
			grid-template-columns: 1fr 1fr;
		}

		@media (min-width: $md) {
			grid-auto-flow: row;
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media (min-width: $lg) {
			grid-auto-flow: column;
			grid-auto-columns: 1fr;
		}

		@media (min-width: $xl) {
			grid-auto-flow: column;
			grid-auto-columns: 1fr;
		}
	}

	.bc-banner {
		background-color: #dbd4c2;
		height: 80px;
		width: 100%;
	}

	.bc-details {
		background-color: #ffffff;

		text-align: center;

		display: flex;
		flex-direction: column;
		align-items: center;

		padding-top: 88px;
		padding-bottom: 88px;

		h1 {
			font-family: refrigerator-deluxe;
			font-style: normal;
			font-weight: 700;
			font-size: 72px;
			line-height: 100%;

			text-transform: uppercase;

			color: #8a7f52;

			margin-bottom: 32px;
		}

		p {
			// big
			@media (min-width: $xl) {
				max-width: 50%;
				// padding-left: 0px;
				// padding-right: 120px;
			}

			// medium
			@media (max-width: $xl) {
				max-width: 75%;
			}

			// small
			@media (max-width: $md) {
				padding-left: 24px;
				padding-right: 24px;
				max-width: 100%;
				text-align: start;
			}
		}
	}

	.bc-discord {
		background-color: #000000;
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;

		padding-top: 88px;
		padding-bottom: 88px;

		h2 {
			font-family: refrigerator-deluxe;
			font-style: normal;
			font-weight: 700;
			font-size: 40px;
			line-height: 100%;

			text-transform: uppercase;

			color: #ffffff;
		}

		.btn {
			padding-top: 16px;
			padding-bottom: 16px;
			padding-left: 48px;
			padding-right: 48px;

			margin-top: 32px;
		}
	}

	.bc-header {
		background-color: #dbd4c2;
		padding: 20px;

		.bc-title {
			color: #000000;

			font-family: refrigerator-deluxe, sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 72px;

			text-transform: uppercase;
		}
	}

	.bc-row {
		display: flex;
		flex-direction: row;
	}
}
