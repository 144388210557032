@import '../../App.scss';

.added-to-cart {
	position: fixed;
	right: 0;
	top: 0;

	margin: 48px;

	background-color: #ffffff;
	border-radius: 8px;
	padding: 24px;

	z-index: 1000;

	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

	.close-button {
		position: absolute;
		right: 0;
		top: 0;
		width: 24px;
		height: 24px;
		margin-top: -12px;
		margin-right: -12px;
		background-color: #ffffff;
		border-radius: 12px;
		padding: 2px;
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
			background-color: #dfdfdf;
		}
	}

	a {
		color: #000000;

		margin-top: 16px;

		font-family: interstate, sans-serif;
		font-style: normal;
		font-weight: 200;
		font-size: 16px;
		line-height: 32px;

		align-self: flex-end;
	}
}
